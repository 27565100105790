import React, { useState, useContext }  from 'react'
import { CurrentUserContext } from "../../context/CurrentUserContext.js";
import { Navbar } from 'react-bulma-components';
import {Link, NavLink} from 'react-router-dom';
import './Header.css';
import AuthForm from '../Auth/AuthForm/AuthForm';
import fullLogoMain from "../../images/bsculptor_logo_main.svg";
import dropdown from "../../images/Vector7777.svg";
import { PROFILE_INFO, HEADER_INFO } from "../../utils/constants";

function Header(props) {
  const [isShowBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isShowProfileMenu, setShowProfileMenu] = useState(false);
  const currentUser = useContext(CurrentUserContext);

  function handleOpenModal() {
    props.openModal(true);
    setShowBurgerMenu(false);
  }

  function onLogout () {
    localStorage.removeItem('token');
    window.location.href = '/'; 
  }

  const handleBurgerClick = () => {
    setShowBurgerMenu(!isShowBurgerMenu);
  };

  const renderProfileMenu = 
    <>
      <ul>
        <li className='header__nav-profile-item'><NavLink to="/profile" style={{color: 'black'}}>{PROFILE_INFO.profileData}</NavLink></li>
        <li className='header__nav-profile-item'><NavLink to="/profile" style={{color: 'black'}}>{PROFILE_INFO.services}</NavLink></li>
        <li className='header__nav-profile-item'><NavLink to="" style={{color: 'red'}} onClick={onLogout}>{PROFILE_INFO.logOut}</NavLink></li>
      </ul>
    </>

  const renderBrand =
    <>
      <Navbar.Item href="/" paddingless={true}>
        <img
          alt="Логотип bsculptor"
          src={fullLogoMain}
        />
      </Navbar.Item>
    </>

  const renderLoginButton =
      <button className="header__nav-button header__nav-button_type_transparent" onClick={handleOpenModal}>
        {HEADER_INFO.login}/{HEADER_INFO.register}
      </button>

  return (
    <>
      <header>
        <Navbar className={`header ${isShowBurgerMenu ? "header_mobile" : ""}`}>
          <Navbar.Brand>
            {renderBrand}
          </Navbar.Brand>
          <Navbar.Burger onClick={handleBurgerClick}/>
          <Navbar.Menu className={`header__profile-menu ${isShowBurgerMenu ? 'header__profile-menu_mobile' : ""}`}>
            <Navbar.Container>
              {props.isLoggedIn ? 
                <Navbar.Item href="/profile" className='is-hidden-desktop link'  backgroundColor='transparent' textSize={5}>
                  {currentUser.email} 
                </Navbar.Item>
               : 
                <Navbar.Item className='is-hidden-desktop' backgroundColor='primary'>
                  {renderLoginButton} 
                </Navbar.Item>}
              <Navbar.Item href="/" backgroundColor='primary'>
                {HEADER_INFO.checkYourIdea}
              </Navbar.Item>
              {props.isLoggedIn &&
                <Navbar.Item backgroundColor='primary' >
                  <Link to="/other-ideas" style={{color: '#4a4a4a'}}>{HEADER_INFO.evaluateOtherIdeas} </Link>
                </Navbar.Item>
              }
              <Navbar.Item href="/promo" backgroundColor='primary' >
                {PROFILE_INFO.services}
              </Navbar.Item>
              {props.isLoggedIn && 
                <Navbar.Item className='is-hidden-desktop' textColor='danger' backgroundColor='primary' onClick={onLogout}>
                  {PROFILE_INFO.logOut}
                </Navbar.Item>}
            </Navbar.Container>
            <Navbar.Container className='header__profile-container is-hidden-touch'>
              <Navbar.Item backgroundColor='primary'>
              {props.isLoggedIn ?           
                (
                  <div className='header__nav-profile' onClick={() => setShowProfileMenu(!isShowProfileMenu)}>
                    <img className={`header__nav-profile-dropdown ${isShowProfileMenu ? "header__nav-profile-dropdown-up" : ""}`} src={dropdown} alt="стрелочка дропдаун"/>
                    <p className="header__nav-profile-email">{currentUser.email}</p>
                    {isShowProfileMenu ? renderProfileMenu : null}
                  </div>
                )
                :
                (
                  renderLoginButton
                )}
              </Navbar.Item>
            </Navbar.Container>
          </Navbar.Menu>
        </Navbar>
      </header>
      <AuthForm
        isOpen={props.isOpen}
        onClose={props.onClose}
        onRegister={props.onRegister}
        handleContinueClick={props.handleContinueClick}
        userStatus={props.userStatus}
        requestStatus={props.requestStatus} 
        isExist={props.isExist}
        onLogin={props.onLogin}
        closeModal={props.closeModal}/>
    </>
  )
}

export default Header;